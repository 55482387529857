"use strict";

const CONFIGURATION = {
  "mapOptions": {
    "center": {"lat": 48.8566, "lng": 2.3522},
    "zoom": 11,
    "styles": [
      { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
      { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#000000' }] }, // Noms des villes en noir
      { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#dadada' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#92bae9' }] // Bleu pour les mers et océans
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
      },
      {
        featureType: 'landscape.natural.landcover', // Utilisation de beige pour les zones naturelles couvertes
        elementType: 'geometry',
        stylers: [{ color: '#f5f1ea' }]
      },
      {
        featureType: 'landscape.natural.terrain', // Utilisation de beige pour le terrain naturel
        elementType: 'geometry',
        stylers: [{ color: '#f5f1ea' }]
      }
    ]
  },
};


const SHORT_NAME_ADDRESS_COMPONENT_TYPES = new Set(['street_number', 'administrative_area_level_1', 'postal_code']);

const ADDRESS_COMPONENT_TYPES_IN_FORM = [
  'location',
  'locality',
  'administrative_area_level_1',
  'postal_code',
  'country',
];

function getFormInputElement(componentType) {
  return document.getElementById(`${componentType}-input`);
}

function fillInAddress(place) {
  function getComponentName(componentType) {
    for (const component of place.address_components || []) {
      if (component.types[0] === componentType) {
        return SHORT_NAME_ADDRESS_COMPONENT_TYPES.has(componentType) ? component.short_name : component.long_name;
      }
    }
    return '';
  }

  function getComponentText(componentType) {
    return (componentType === 'location') ? `${getComponentName('street_number')} ${getComponentName('route')}` : getComponentName(componentType);
  }

  for (const componentType of ADDRESS_COMPONENT_TYPES_IN_FORM) {
    getFormInputElement(componentType).value = getComponentText(componentType);
  }
  if (place.geometry) {
    const latitudeField = document.getElementById('latitude-input');
    const longitudeField = document.getElementById('longitude-input');

    if (latitudeField && longitudeField) {
      latitudeField.value = place.geometry.location.lat();
      longitudeField.value = place.geometry.location.lng();
    }
  }
}

function renderAddress(place, map, marker) {
  if (place.geometry && place.geometry.location) {
    map.setCenter(place.geometry.location);
    marker.setPosition(place.geometry.location);
  } else {
    marker.position = null;
  }
}

document.addEventListener("turbo:load", initMap);
document.addEventListener("turbo:render", initMap);

export function initMap() {
  const { Map, Marker } = google.maps;
  const { Autocomplete } = google.maps.places;

  const mapElement = document.getElementById('gmp-map-two');
  if (!mapElement) {
    console.log('Element with id "gmp-map-two" not found. Skipping map initialization.');
    return;
  }

  const map = new Map(mapElement, CONFIGURATION.mapOptions);

  const marker = new Marker({
    map: map,
    icon: {
      url: '/assets/logo_maps.png', // Chemin vers votre image
      scaledSize: new google.maps.Size(22.8, 35) // Taille de l'icône
    },
    title: 'Votre texte de légende'
  });

  const locationInputElement = getFormInputElement('location');
  if (!(locationInputElement instanceof HTMLInputElement)) {
    console.log('Element with id "location" not found or not an input element. Skipping autocomplete initialization.');
    return;
  }

  const autocomplete = new Autocomplete(locationInputElement, {
    fields: ['address_components', 'geometry', 'name'],
    types: ['address'],
    componentRestrictions: { country: "fr" }
  });

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (!place.geometry) {
      window.alert(`No details available for input: '${place.name}'`);
      return;
    }
    renderAddress(place, map, marker);
    fillInAddress(place);
  });
}

